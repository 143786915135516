<template>
  <div>
    <div class="transform  h-full inset-0 overflow-auto ease-in-out transition-all duration-300 z-50"
        aria-labelledby="slide-over-title" role="dialog" aria-modal="true"
        :class="cartNavbar ? 'fixed' : ''">
        <div class="absolute inset-0 overflow-hidden">
          <div class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <div class="w-screen max-w-md">
                <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="flex-1 py-6 overflow-y-auto px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                        {{ $t('Cart') }}
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                        <button type="button" class="-m-2 p-2 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Close panel</span>
                        <svg @click="close" class="h-7 w-7" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        </button>
                    </div>
                    </div>

                    <div class="mt-8">
                    <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200" v-if="cartItems.length">
                          <li class="py-6 flex" v-for="(item, index) in cartItems" :key="index">
                              <div class="flex-shrink-0 w-24 h-24 border border-gray-200 rounded-md overflow-hidden">
                                <img v-if="item.img" :src="item.img" :alt="'Problème chargement d\'image'"  class="w-full h-full object-center object-cover">
                                <img v-else src="@/assets/images/no-image-400x400.png" class="w-full h-full object-center object-cover">
                              </div>

                              <div class="ml-4 flex-1 flex flex-col">
                              <div>
                                  <div class="flex justify-between text-base font-medium text-gray-900">
                                  <h3>
                                    {{ item.quantityRequested }} x {{ item.title.length >= 18 ?  item.title.substring(0, 18) + '...' : item.title }}
                                  </h3>
                                  <p class="ml-4">
                                      {{ item.product_price }} €
                                  </p>
                                  </div>
                                  <p class="mt-1 text-xs text-gray-500">
                                    {{ $moment(item.selectedStartEndDateTime.startDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') }} {{ $moment(item.selectedStartEndDateTime.startTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') }} / {{ $moment(item.selectedStartEndDateTime.endDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') }} {{ $moment(item.selectedStartEndDateTime.endTime, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') }}
                                  </p>
                                  <div class="product-options text-xs text-gray-600">
                                    <div class="">
                                      <div class="mt-2" v-if="showProductOptions(item.allOptions)">
                                      <h6 class="mb-1 ">Option(s):</h6>
                                      <vs-col 
                                        class="flex mb-1" 
                                        vs-type="flex" 
                                        v-for="(productOption, index) in item.allOptions" 
                                        :key="index"
                                      >
                                      
                                      <span class="block truncate" v-if="productOption.quantityRequested"> <b>{{ productOption.quantityRequested }} x</b> {{ productOption.title }} <b>{{ productOption.price }} €</b> </span>
                                      </vs-col>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div class="flex items-end justify-between text-sm mt-1">
                                  <div class="flex">
                                    <button type="button" @click.stop="removeItemFromCart(index, item)" class="font-medium text-red-600	hover:text-red-500">{{ $t('remove') }}</button>
                                    <!-- <button type="button" class="font-medium text-primary hover:text-indigo-500">{{ $t('Update') }}</button> -->
                                  </div>

                                  <div class="flex">
                                      {{ item.total }} €
                                  </div>
                              </div>
                              </div>
                          </li>
                        </ul>
                    </div>
                    </div>
                </div>

                <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <div class="flex justify-between text-base font-medium text-gray-900">
                    <p>Total</p>
                    <p>{{ total }} €</p>
                    </div>
                    <p class="mt-0.5 text-sm text-gray-500">Taxe incluse.</p>
                    <div class="mt-6">
                      <button @click="goNextStep()" class="flex justify-center items-center w-full px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-primary hover:opacity-75" rel="noopener noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Valider
                      </button>
                    </div>
                    <div class="mt-6 flex justify-center text-sm text-center text-gray-500">
                    <p>
                        ou <button type="button" @click="close" class="text-primary font-medium hover:opacity-75">{{ $t('ContinueShopping') }}<span aria-hidden="true"> &rarr;</span></button>
                    </p>
                    </div>
                </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    close() {
      this.$store.commit("IS_CART_NAVBAR_ACTIVE", false);
    },
    removeItemFromCart (index, item) {
      if(typeof(item.productsheetIsExis) == 'undefined' && item.productsheetIsExist == true) {
        this.acceptAlert()
        return
      }

      const data = {
        index,
        item
      }
      this.$store.dispatch('eCommerce/removeItemInCart', data)
    },
    showProductOptions(productOptions) {
      let showOptions = false
      productOptions.forEach(productOption => {
        if(productOption.quantityRequested > 0) {
          showOptions = true
          return showOptions;
        }
      });
      return showOptions
    },
    goNextStep() {
      this.close()
      this.$router.push({ name: 'reservation-client-login', params: { slug: this.$route.params.slug } })
    }
  },
  computed: {
    cartNavbar: function() {
      return this.$store.state.cartNavbar;
    },
    cartItems () {
      return this.$store.state.eCommerce.cartItems
    },
    total () {
      return this.$store.getters['eCommerce/getCartTotal']
    },
  },

  watch: {

  },
  mounted() {
   
  }
};
</script>